<template>
    <div class="col-12 col-lg-7 workspace workspace-card">
        <div class="workspace-card-header">
            <div class="row align-items-top px-2">
                <div class="col-12 col-md-6 col-lg-2">
                    <div class="data">
                        <h6 class="data-title">
                            ID
                        </h6>

                        <span class="data-content text-break">
                            {{ version.dataMap.id }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <div class="data">
                        <h6 class="data-title">
                            Version
                        </h6>

                        <span class="data-content">
                            {{ versionTitle }}
                            <span class="text-muted">
                                (ID: {{ version.id }})
                            </span>
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <div class="data">
                        <h6 class="data-title">
                            Title
                        </h6>

                        <span class="data-content version-title text-break">
                            {{ version.title }}
                        </span>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <div class="data">
                        <h6 class="data-title">
                            Policy System
                        </h6>

                        <span class="data-content version-policy-system">
                            {{ version.dataMap.policySystemId === null ? 'All' : dataMapPolicySystem }}
                        </span>
                    </div>
                </div>

                <div
                    v-if="!readOnly"
                    class="col-1 ml-n3 align-self-center align-items-center pb-2 pb-lg-0"
                >
                    <div class="data">
                        <h6 class="data-title d-lg-none">
                            Options
                        </h6>

                        <FontAwesomeIcon
                            id="dataMapOptions"
                            icon="cog"
                            size="2x"
                            class="action options"
                        />

                        <BTooltip
                            target="dataMapOptions"
                            triggers="hover"
                            placement="left"
                            title="Data Map Options"
                            custom-class="d-none d-lg-block"
                        />
                    </div>
                </div>
            </div>
        </div>

        <Draggable
            v-model="list"
            class="workspace-operations accepts-drops"
            draggable=".operation"
            data-cy="dropContainer"
            :group="{name: 'operations', put: true}"
        >
            <template
                v-if="list.length"
            >
                <MWorkspaceOperation
                    v-for="(operation, index) in list"
                    :key="uniqKey(operation)"
                    :operation="operation"
                    :step="index + 1"
                    :is-saved="operation.id !== undefined"
                    :read-only="readOnly"
                    :class="['operation', {'d-none': filter.length && !titleMatchesFilter(operationTitle(operation))}]"
                    data-cy="droppedOperation"
                    @updated="updateOperation(operation, $event)"
                />
            </template>

            <div
                v-else
                class="text-muted text-center my-4"
            >
                <FontAwesomeIcon
                    icon="empty-set"
                />
                No operations for this data map have been added yet.
            </div>
        </Draggable>
    </div>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import versioningMixin from '@imt/vue-versioning/src/mixins/versioning';
    import cloneDeep from 'lodash/cloneDeep'; // eslint-disable-line you-dont-need-lodash-underscore/clone-deep
    import set from 'lodash/set';
    import Draggable from 'vuedraggable';
    import {mapGetters, mapMutations, mapState} from 'vuex';

    import MWorkspaceOperation from './MWorkspaceOperation.vue';

    export default {
        name: 'MWorkspace',
        components: {
            Draggable,
            MWorkspaceOperation,
        },
        mixins: [toastsMixin, versioningMixin],
        props: {
            readOnly: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                operation: {}
            };
        },
        computed: {
            dataMapPolicySystem() {
                return this.formattedPolicySystems?.find(ps => ps.id === this.version.dataMap.policySystemId?.toString())?.title;
            },
            list: {
                get() {
                    return this.version.operations || [];
                },
                set(value) {
                    if (!this.isPublished(this.version)) {
                        value.forEach((operation, index) => {
                            let clonedOperation = cloneDeep(operation);
                            clonedOperation.order = index;
                            value[index] = clonedOperation;
                        });

                        this.$store.dispatch('builder/updateOperations', value);
                    } else {
                        this.error(
                            'You cannot modify operations within a published version.',
                            'Update Operations'
                        );
                    }
                },
            },
            versionTitle() {
                return (this.isDraft(this.version) === true ? 'Draft' : this.version.id);
            },
            ...mapState('builder', [
                'filter',
                'saving',
                'version',
            ]),
            ...mapGetters('toolbox', [
                'formattedPolicySystems',
            ]),
        },
        methods: {
            operationTitle(operation) {
                return operation.function || operation.title;
            },
            titleMatchesFilter(title) {
                return RegExp(this.filter, 'gi').test(title);
            },
            uniqKey(operation) {
                return operation.id || `${this.operationTitle(operation)}-${Math.ceil(Math.random() * 10000)}`;
            },
            updateOperation(operation, {
                key,
                value,
            }) {
                this.$set(this, 'operation', set(operation, key, value));
                this.SET_DATA({
                    type: 'hasChanges',
                    data: true,
                });
            },
            ...mapMutations('builder', [
                'SET_DATA',
            ]),
        },
    };
</script>

<style
    lang="sass"
    scoped
>
    @keyframes wiggle
        0%
            transition: transform 300ms
            transform: rotate(0deg) translate(0, 0)
        25%
            transition: transform 300ms
            transform: rotate(-0.25deg) translate(1px, 0)
        50%
            transition: transform 300ms
            transform: rotate(0deg) translate(1px, 1px)
        75%
            transition: transform 300ms
            transform: rotate(0.25deg) translate(0, 1px)

    .workspace
        display: flex
        flex-direction: column

        .accepts-drops
            min-height: 4rem

        &.workspace-card
            display: flex
            flex-direction: column
            border-radius: var(--card-border-radius)
            background-color: var(--kc-gray-300)
            padding: 0

            .workspace-card-header
                display: flex
                flex-direction: column
                border-radius: var(--card-border-radius) var(--card-border-radius) 0 0
                background-color: var(--kc-blue-500)
                color: var(--kc-gray-100)

                .text-muted
                    color: var(--kc-gray-200) !important

                .action
                    &.options
                        color: var(--kc-gray-100)

                        &:hover
                            color: var(--kc-gray-300)
                            cursor: pointer

                .data
                    display: flex
                    flex-direction: column
                    padding: 0.5rem

                    .data-title,
                    .data-content
                        width: 100%

                    .data-title
                        font-weight: bold
                        font-size: 0.85rem

                    .data-content
                        font-weight: normal
                        font-size: 1rem

            .workspace-operations
                padding: 1rem

                .workspace-operation,
                .sortable-ghost
                    &:not(:last-child)
                        margin-bottom: 0.5rem

                .sortable-ghost
                    animation: wiggle infinite linear 300ms

    .mode
        &.dark
            .workspace-operations
                background-color: var(--kc-blue-800)

            .workspace
                &.workspace-card
                    background-color: var(--kc-blue-800)

                    .workspace-card-header
                        background-color: var(--kc-blue-600)
</style>
